import { ProductService as _ProductService } from "@ultracommerce/react-storefront/global";
import { ProductFactory as _ProductFactory } from "@ultracommerce/react-storefront/global/src/factories/ProductFactory";
import { ProductSearchFactory as _ProductSearchFactory } from "@ultracommerce/react-storefront/global/src/factories/ProductSearchFactory";
import { IOptionSet } from "../pages/ProductDetailsPage/CustomOptions";

function parseProductDescription(
  productDescription: string = "",
  specifications: any = ""
) {
  let parsed: any = {};

  if (productDescription) {
    let splitsections = productDescription.includes('\r\n') ? productDescription
      ?.split("||")
      .map((item: string) => item.split("\r\n")) : productDescription
        ?.split("||")
        .map((item: string) => item.split("\n"));

    if (!splitsections.length) return productDescription;
    let relatedDocumentsArray: [] = [];
    splitsections.forEach((section: any) => {
      if (!section[1]) return; //abort is there is no content
      const normailsedSectionProperty = section[0]
        .toLowerCase()
        .replace(/\W/g, "");

      if (normailsedSectionProperty !== 'relateddocuments') {
        parsed[normailsedSectionProperty] = {
          title: section[0],
          content: section[1],
        };
      } else {
        relatedDocumentsArray = section.filter((e: any) => e !== '').map((item: string) => {
          return item;
        })
        parsed[normailsedSectionProperty] = {
          title: section[0],
          content: relatedDocumentsArray
        }
      }

    });
  }

  if (parsed?.featuresbenefits)
    parsed.featuresbenefits.content =
      parsed.featuresbenefits.content.split(" | ");

  if (parsed.relateddocuments) {
    let relatedDocumentsContent = [];
    relatedDocumentsContent = parsed.relateddocuments.content.filter((e: any,index: number) => index !== 0).map((file: any) => {
      return { title: file.split("|")[0], link: file.split(" | ")[1] };
    });
    parsed.relateddocuments.content = relatedDocumentsContent;
  }

  if (specifications) {
    parsed.specifications = { title: "", content: "" };
    parsed.specifications.title = "Specifications";
    parsed.specifications.content = specifications.includes('\r\n') ?
      specifications.split("\r\n")
        .map((item: any) => {
          return { key: item.split(" | ")[0], value: item.split(" | ")[1] };
        }) :
      specifications.split("\n")
        .map((item: any) => {
          return { key: item.split(" | ")[0], value: item.split(" | ")[1] };
        })
  }

  return parsed;
}

function parseOptionSets(OptionSets: any = ""): undefined | IOptionSet[] {
  if (!OptionSets) return undefined;
  return OptionSets.split("[")
    .filter((e: any) => e)
    .map((optionSet: string) => {
      const data = optionSet.split("]");
      const config = data[0].replace("[", "");
      const options = data[1].split(/\s*(?:\n)\s*/).filter((e: any) => e);

      return {
        key: config.split(";")[0].replaceAll(" ", "") as string,
        title: config.split(";")[0] as string,
        required: config.split(";")[1]?.includes("required") as boolean,
        options: options.map((option) => {
          const [title, modifier = "+0", code = ""] = option.split(";");
          return { title, modifier, code };
        }),
      };
    });
}

class ProductSearchFactory extends _ProductSearchFactory {
  constructor(...arg: ConstructorParameters<typeof _ProductSearchFactory>) {
    super(...arg);
    const data = arg[0];
    this.products = (data.products || []).map((product) => {
      const description = parseProductDescription(
        (product as any).extendedProductDescription,
        (product as any).descriptionSpecification
      );
      return {
        ...product,
        skuDescription: description?.shortdescription?.content,
        productDescription: description?.shortdescription?.content,
      };
    }) as any;
  }
}

class ProductFactory extends _ProductFactory {
  constructor(...arg: ConstructorParameters<typeof _ProductFactory>) {
    super(...arg);
    const [data] = arg;
    const parsedDescription = parseProductDescription(
      (data?.product as any).extendedProductDescription,
      (data?.product as any).descriptionSpecification
    );
    const parsedOptionSets = parseOptionSets((data?.product as any).OptionSet);
    this.product = {
      ...this.product,
      // @ts-ignore
      clientCustom: {
        extendedProductDescription: parsedDescription,
        optionSets: parsedOptionSets,
      },
    };
  }
}

class ProductService extends _ProductService {
  constructor() {
    super({
      productSearchFactory: ProductSearchFactory,
      // @ts-ignore
      productFactory: ProductFactory,
    });
  }
  
}

export { ProductService };
