import {
  useElementContext,
  ProductContextProvider,
} from "@ultracommerce/react-storefront/global";

import View from "./ProductDetailsPage.pageView";
import { useComponentModel } from "@ultracommerce/react-storefront/global/src/pages/ProductDetailsPage/ProductDetailsPage.pageService";
import { useEffect, useState } from "react";
import { DEFAULT_CUSTOM_OPTIONS, IOptionSet } from "./CustomOptions";
import { useLivePrice } from "../../hooks/components/ProductDetail/useLivePricing";
import { useSelector } from "react-redux";

const ProductDetailPage = () => {
  const {
    PageModule: { DynamicPage },
  } = useElementContext();
  return (
    <DynamicPage>
      {({ renderDynamicContent }: any) => (
        <>
          <ProductContextProvider>
            <ProductDetailPageView />
          </ProductContextProvider>
          {renderDynamicContent()}
        </>
      )}
    </DynamicPage>
  );
};

const ProductDetailPageView = () => {
  const { componentData } = useComponentModel();
  // Add config override for product detail here
  const [customOptions, setCustomOptions] = useState<{
    [key: string]: IOptionSet["options"][number];
  }>(DEFAULT_CUSTOM_OPTIONS);
  const Note = (componentData.product as any)?.Note;
  const extendedProductDescription = (componentData.product as any).clientCustom
    ?.extendedProductDescription;
  const currencyCode = useSelector(
    (state: any) => state?.configuration?.site?.currencyCode
  );
  const selectedSku = componentData?.selectedSKu;
  const [isFetchingPrice, setIsFetchingPrice] = useState(true);

  const {
    isFetchingLivePrice,
    salePrice,
    listPrice,
    inventoryLocations,
    QATS,
    QOH,
    restrictedFlag,
    skuAllowBackorderFlag,
  } = useLivePrice({
    sku: selectedSku,
    currencyCode,
  });

  useEffect(() => {
    if (!isFetchingLivePrice && isFetchingPrice) {
      setIsFetchingPrice(false);
    }
    // eslint-disable-next-line
  }, [isFetchingLivePrice, isFetchingPrice, setIsFetchingPrice]);

  const optionSets = (componentData.product as any).clientCustom?.optionSets as
    | IOptionSet[]
    | undefined;

  componentData.clientCustom = {
    customOptions,
    setCustomOptions,
    skuConfiguration: { options: customOptions },
    extendedProductDescription,
    optionSets,
    isFetchingPrice,
    setIsFetchingPrice,
    salePrice,
    listPrice,
    inventoryLocations,
    restrictedFlag,
    QATS,
    skuAllowBackorderFlag,
    QOH,
    settings: (componentData.product as any).settings,
    brandName: (componentData.product as any).brand_brandName,
    brandUrlTitle: (componentData.product as any).brand_urlTitle,
    BCSManufacturerPartNumber: (componentData.product as any)
      .BCSManufacturerPartNumber,
    Note,
  };
  optionSets?.forEach((optionSet) => {
    if (optionSet?.required && !customOptions[optionSet.key]) {
      componentData.productForm = undefined;
    }
  });

  if (componentData.productForm)
    componentData.productForm.skuConfiguration = { options: customOptions };

  return <View {...componentData} />;
};

export default ProductDetailPage;
